import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../images/gatsby-icon.png';

const SEO = ({ title, description, image, article, url }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    appleAppId,
    googleAppId,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${url || siteUrl}/${image || defaultImage}`,
    url: url || `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      {/* OpenGraph metadata */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.url && <link rel="canonical" href={seo.url} />}
      {seo.title && <meta name="title" content={seo.title} />}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.image && <meta name="image" content={seo.image} />}
      {/* App stores metadata */}
      {appleAppId && <meta name="apple-itunes-app" content={appleAppId} />}
      {appleAppId && <link rel="apple-touch-icon" href={seo.image} />}
      {googleAppId && <meta name="google-play-app" content={googleAppId} />}
      {googleAppId && <link rel="android-touch-icon" href={seo.image} />}
      <meta name="google-site-verification" content="WbVdL1N-SVwEEDs3yCNxpEoWJVJF6H0UGzW0bDYiGyw" />

      {/* <!-- BEGIN Usercentrics related code --> */}
      <link rel="icon" href={favicon} />
      <link rel="canonical" href="http://имот.com" />
      <link rel="preconnect" href="https://app.usercentrics.eu" />

      <link rel="preconnect" href="https://api.usercentrics.eu" />

      <link rel="preconnect" href="https://privacy-proxy.usercentrics.eu" />

      <link
        rel="preload"
        href="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        as="script"
      />

      <link
        rel="preload"
        href="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
        as="script"
      />

      {/* <script type="application/javascript" settingsId='yv-R7fFX' src="https://app.usercentrics.eu/latest/main.js" id="yv-R7fFX" ></script> */}

      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="94a5ca89-7995-4a8a-a48d-3c92eda2b9fb"
        data-blockingmode="auto"
        type="text/javascript"
      ></script>

      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/94a5ca89-7995-4a8a-a48d-3c92eda2b9fb/cd.js"
        type="text/javascript"
        async
      ></script>

      {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-2YK4LTXBL5"></script>
      <script>
        {`
        window.dataLayer = window.dataLayer || []; 
        function gtag(){
        dataLayer.push(arguments);} 
        gtag('js', new Date()); gtag('config', 'G-2YK4LTXBL5'); 
        `}
      </script> */}
      {/* 
      <script>

        {`// (optional) additional configs for the Smart Data Protector

            uc.deactivateBlocking([

              "BJ59EidsWQ", // Google Tag Manager is not blocked

              "HkocEodjb7", // Google Analytics is not blocked

            ]);

          `}

      </script> */}

      {/* <!-- END Usercentrics related code --> */}

      {/* Twitter metadata */}

      <meta name="twitter:card" content="summary_large_image" />
      {seo.url && <meta name="twitter:domain" content={seo.url} />}
      {seo.url && <meta name="twitter:url" content={seo.url} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};
export default SEO;
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultDescription: description
        siteUrl: url
        defaultTitle: title
        appleAppId
        googleAppId
        defaultImage: image
        titleTemplate
      }
    }
  }
`;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};
